import { FC } from 'react'
import cn from 'classnames'
import styles from './TimeToRead.module.scss'
import { declOfNum } from '../../utils/declOfNum'

interface TimeToReadProps {
  min: number
  className?: string
}

const TimeToRead: FC<TimeToReadProps> = ({ min, className }) => {
  return (
    <span className={cn(styles.ttr, 'h4', className)}>
      {min}{' '}
      {declOfNum(min, [
        'минута на чтение',
        'минуты на чтение',
        'минут на чтение',
      ])}
    </span>
  )
}

export default TimeToRead

import { FC } from 'react'
import Link from 'next/link'
import styles from './Tags.module.scss'
import { Tag } from '../../interfaces/Tags'
import cn from 'classnames'

interface TagsProps {
  tags: { id: string; name: string; slug?: string }[]
  className?: string
}

const Tags: FC<TagsProps> = ({ tags, className }) => {
  if (!tags || tags?.length === 0) {
    return null
  }

  return tags?.length === 1 ? (
    <>
      {tags[0].slug ? (
        <Link
          className={cn('h4', styles.tag, className)}
          href={`/tag/${tags[0].slug}`}
        >
          [ <span className={styles.text}>{tags[0].name}</span> ]
        </Link>
      ) : (
        <span className={cn('h4', styles.tag, className)}>
          [ <span className={styles.text}>{tags[0].name}</span> ]
        </span>
      )}
    </>
  ) : (
    <ul className={cn(styles.tags, className)}>
      {tags?.map((tag) => (
        <li key={tag.id} className={cn('h4', styles.tag)}>
          {tag.slug ? (
            <Link href={`/tag/${tag.slug}`}>
              [ <span className={styles.text}>{tag.name}</span> ]
            </Link>
          ) : (
            <>
              [ <span className={styles.text}>{tag.name}</span> ]
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Tags

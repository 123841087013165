import Link from 'next/link'
import { FC } from 'react'
import cn from 'classnames'

import styles from './PostMain.module.scss'
import { PostWithHtmlFragment } from '../../queries/generated/blog/schema'
import { PreviewPostFragment } from '../../queries/generated/blog/schema'
import { CornersImage } from '../common/CornersImage/CornersImage'
import { AuthorSmall } from '../common/AuthorSmall/AuthorSmall'
import DateText from './DateText'
import TimeToRead from './TimeToRead'
import Tags from './Tags'

interface PostMainProps {
  data: PostWithHtmlFragment | PreviewPostFragment
  className?: string
  sizes?: string
  priority?: boolean
}

const PostMain: FC<PostMainProps> = ({ data, className, sizes, priority }) => {
  let htmlArray: string[] = []
  let firstParagraph = ''

  if ('html' in data) {
    htmlArray = data.html.split('</p>')
    firstParagraph = htmlArray[0].replace(/<[^>]*>/g, '')
  }

  return (
    <div className={styles.outer}>
      <div className={styles.linkWrapper}>
        <Link className={cn(styles.grid, className)} href={`/${data.slug}`}>
          <div className={styles.imageWrapper}>
            <figure className={styles.imageFigure}>
              {data.picture?.url && (
                <CornersImage
                  className={styles.image}
                  src={data.picture?.url}
                  width={835}
                  height={702}
                  loader={({ src, width }) =>
                    `${
                      process.env.NEXT_PUBLIC_IMAGE_OPTIM
                    }/pr:sharp/rs:fill/w:${width}/q:${75}/plain/${encodeURIComponent(
                      src
                    )}`
                  }
                  loading={priority ? 'eager' : 'lazy'}
                  priority={priority}
                  sizes={
                    sizes ||
                    '(max-width: 20rem) 288px, (max-width: 23.4375rem) 343px, (max-width: 26.625rem) 393px, (max-width: 48rem) 736px, (max-width: 64rem) 424px, (max-width: 90rem) 625px, 835px'
                  }
                  alt="Изображение статьи"
                />
              )}
            </figure>
            <div className={styles.imageInner}>
              <p className={cn(styles.title, 'text-l')}>
                <span className={styles.line}>{data.title}</span>
              </p>
            </div>
          </div>
        </Link>
        {data?.excerpt ? (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: data.excerpt }}
          />
        ) : firstParagraph ? (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: firstParagraph }}
          />
        ) : (
          <></>
        )}
        <Tags className={styles.tags} tags={data?.tags} />
      </div>

      <DateText
        date={data?.published_at}
        className={cn(styles.date, styles.dateMobile)}
      />

      <div className={styles.bottom}>
        <div className={styles.leftRow}>
          <AuthorSmall author={data.authors[0]} className={styles.author} />
        </div>
        <div className={styles.dateWithTime}>
          <DateText date={data?.published_at} className={styles.date} />
          <TimeToRead min={data.readingTime} className={styles.readingTime} />
        </div>
      </div>

      <div className={styles.bottomMobile}>
        <AuthorSmall author={data.authors[0]} className={styles.author} />
        <TimeToRead min={data.readingTime} className={styles.readingTime} />
      </div>
    </div>
  )
}

export default PostMain

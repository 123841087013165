import { FC } from 'react'
import cn from 'classnames'
import styles from './DateText.module.scss'
import { getUtcDate, newsDate } from '../../utils/dates'

interface DateTextProps {
  date: string
  className?: string
}

const DateText: FC<DateTextProps> = ({ date, className }) => {
  return (
    <span className={cn('h4', styles.date, className)}>
      {newsDate({
        date: new Date(getUtcDate(date)),
      })}
    </span>
  )
}

export default DateText

const getMonthName = (date: Date, useCase = false, locale = 'ru'): string => {
  const ruMonthsCases = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]

  if (locale === 'ru-Ru' || locale === 'ru') {
    return useCase
      ? ruMonthsCases[date.getMonth()]
      : date.toLocaleString(locale, { month: 'long' })
  }

  return date.toLocaleString(locale, { month: 'long' })
}

const getDay = (date: Date, withLeadingZero = false): string => {
  return withLeadingZero
    ? ('0' + date.getDate()).slice(-2)
    : `${date.getDate()}`
}

export const newsDate = ({
  date,
  locale = 'ru',
  withYear = false,
  withComma = true,
}: {
  date: Date
  locale?: string
  withYear?: boolean
  withComma?: boolean
}): string => {
  const now = new Date('2021-12-17T03:24:00')
  const yesterdayNow = new Date('2021-12-17T03:24:00')
  yesterdayNow.setDate(yesterdayNow.getDate() - 1)

  const today = datesAreOnSameDay(now, date)
  const yesterday = datesAreOnSameDay(yesterdayNow, date)

  if (today || yesterday) {
    const hours = now.getHours()
    const minutes = now.getMinutes()

    return `${today ? 'Сегодня' : 'Вчера'}・${
      hours < 10 ? `0${hours}` : hours
    }:${minutes < 10 ? `0${minutes}` : minutes}`
  }

  let res = `${getDay(date)} ${getMonthName(date, true, locale)}`
  const currYear = now.getFullYear()
  const year = date.getFullYear()
  if (withYear || currYear !== year) {
    res += withComma ? `, ${year}` : ` ${year}`
  }
  return res
}

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

export const getTimeFromDate = (date: Date, withSeconds = false) => {
  if (!date) {
    return withSeconds ? '00:00:00' : '00:00'
  }

  const hour = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return withSeconds
    ? `${hour < 10 ? '0' + hour : hour}:${
        minutes < 10 ? '0' + minutes : minutes
      }:${seconds < 10 ? '0' + seconds : seconds}`
    : `${hour < 10 ? '0' + hour : hour}:${
        minutes < 10 ? '0' + minutes : minutes
      }`
}

export const getUtcDate = (date: string): string => {
  const splittedData = date?.split('.')[0]

  if (!splittedData) return null

  const splittedDate = splittedData.split('T')[0]
  const splittedTime = splittedData.split('T')[1]

  const year = +splittedDate.split('-')[0]
  const month = +splittedDate.split('-')[1]
  const day = +splittedDate.split('-')[2]

  const hours = +splittedTime.split(':')[0]
  const minutes = +splittedTime.split(':')[1]
  const seconds = +splittedTime.split(':')[2]

  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds))
    .toISOString()
    .split('Z')[0]
}

export const getDatesFullDay = () => {
  const today = new Date()

  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)

  const day = today.getDate()
  const tomorrow = new Date(today)

  tomorrow.setDate(day + 1)

  return { today: today.toISOString(), tomorrow: tomorrow.toISOString() }
}

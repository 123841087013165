import React, { FC } from 'react'
import styles from './CornersImage.module.scss'
import Image from '../Image'
import { ImageProps } from 'next/image'
import cn from 'classnames'

export const CornersImage: FC<
  ImageProps & {
    withOptimize?: boolean
    className?: string
    skipSizes?: boolean
  }
> = (props) => {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={cn(styles.corner, styles['corner--left-top'])} />
      <div className={cn(styles.corner, styles['corner--right-top'])} />
      <div className={cn(styles.corner, styles['corner--right-bottom'])} />
      <div className={cn(styles.corner, styles['corner--left-bottom'])} />
      <Image {...props} />
    </div>
  )
}

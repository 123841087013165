import Link from 'next/link'
import React, { FC } from 'react'
import styles from './AuthorSmall.module.scss'
import cn from 'classnames'
import { Author } from '../../../interfaces/Author'
import Image from '../Image'

interface IAuthorSmall {
  author: Author
  className?: string
}

export const AuthorSmall: FC<IAuthorSmall> = ({ author, className }) => {
  if (!author || !author.picture?.url) {
    return null
  }

  return (
    <Link
      href={`/author/${author.slug}`}
      className={cn(styles.wrapper, className)}
    >
      {author.picture?.url && (
        <figure className={styles.image}>
          <Image
            src={author.picture.url}
            alt={`Фото ${author.name}`}
            width={30}
            height={30}
            loader={({ src, width, quality }) =>
              `${
                process.env.NEXT_PUBLIC_IMAGE_OPTIM
              }/pr:sharp/rs:fill:${width}:${width}/q:${
                quality || 85
              }/plain/${src}`
            }
          />
        </figure>
      )}
      <span className={styles.name}>{author.name}</span>
    </Link>
  )
}
